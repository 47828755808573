//hooks
import { useSettings } from '../../context/SettingsProvider';

//utils
import { eventcard_accessibility } from '../../assets/icons';
import { image_accessibility_card } from '../../assets/images/explore';

const ExploreAccessibility = () => {
  //hooks
  const { width } = useSettings();

  return (
    <div className="explore-myndfull-translations explore-base-text--small">
      <div className="explore-myndfull-accessibility-title ">
        <img
          src={eventcard_accessibility}
          className="mrg-r12"
          style={{ height: '54px' }}
          alt=""
        />
        <h2 className="text-center text-left--ph">
          A Commitment To Accessibility
        </h2>
      </div>

      {width > 900 ? (
        <div className="flex-row flex-center mrg-t36 mrg-t24--ph">
          <div className="accessibility-card-demo">
            <img src={image_accessibility_card} alt="" />
          </div>

          <p className="mrg-l24">
            At MyndFull, we want academic and educational events to become more
            accessible than ever by providing the tools to make it happen.
            Starting with our own web app, we have prioritized accessible
            navigation from day one. We want our users to think about the
            accessibility of their events too. That is why our Create Event
            process includes options to identify accessibility services. Too
            often these details are not included in event promotions. We believe
            that should change and want to help make this happen.
          </p>
        </div>
      ) : (
        <div className="flex-column flex-center mrg-t24 ">
          <p className="mrg-l24">
            At MyndFull, we want academic and educational events to become more
            accessible than ever by providing the tools to make it happen.
            Starting with our own web app, we have prioritized accessible
            navigation from day one. We want our users to think about the
            accessibility of their events too. That is why our Create Event
            process includes options to indicate whether the event has
            accessibility services available. Too often these details are not
            included in event promotions. We believe that should change and want
            to help make this happen.
          </p>
          <div className="accessibility-card-demo mrg-t24">
            <img src={image_accessibility_card} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExploreAccessibility;
