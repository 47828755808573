const demoBigTags = ['hist', 'edu'];
const demoTinyTags = ['canadianhistory', 'socialhistory'];
const demoTinyTagGroups = [
  {
    id: 22222,
    label: 'WW2',
    tags: ['ww1', 'ww2'],
  },
  {
    id: 11111,
    label: 'WW1',
    tags: ['ww1', 'ww2'],
  },
];

const demoCommunityFollowing = [
  {
    personal: {
      firstName: 'James',
      lastName: 'Combs',
      profileImage: 'dummy-3YWBNAKB3N.jpg',
    },
    _id: '67d30c83f0c2c4752f6e3ad6',
  },
  {
    personal: {
      firstName: 'Mary',
      lastName: 'Shaffer',
      profileImage: 'dummy-4LR3GIBKOQ.jpg',
    },
    _id: '67d30c83f0c2c4752f6e3c65',
  },
  {
    personal: {
      firstName: 'Reading Group',
      lastName: '',
      profileImage: 'book_club_logo.jpg',
    },
    _id: '67d30c83f0c2c4752f23f3c6',
  },
];

const demoCommunityFollowingGroups = [
  {
    id: 123123123,
    label: 'School Friends',
    following: [],
  },
  {
    id: 234243234,
    label: 'Former Professors',
    following: [],
  },
  {
    id: 3453453453,
    label: 'Favourite Publishers',
    following: [],
  },
];

export {
  demoBigTags,
  demoTinyTags,
  demoTinyTagGroups,
  demoCommunityFollowing,
  demoCommunityFollowingGroups,
};
