//hooks
import { useSettings } from '../../../../context/SettingsProvider';

//utils
import { img_messages2, img_social } from '../../../../assets/icons';

const FindEventsCommunityAndEmail = ({ currentIndex }) => {
  //hooks
  const { width } = useSettings();

  return (
    <>
      {((width > 1280 && currentIndex === 3) ||
        (width <= 1280 && currentIndex === 5)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={img_social}
              alt=""
              className="filter-theme mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title">Community</h4>
          </div>
          <p className="features-description mrg-tb24">
            Find events with your favourite speakers and event organizers by
            following them. (Community follows are not publicly visible to other
            users.)
          </p>
        </div>
      )}

      {((width > 1280 && currentIndex === 3) ||
        (width <= 1280 && currentIndex === 6)) && (
        <div className="features__container">
          <div className="pos-rel flex-row align-center mrg-t24">
            <img
              src={img_messages2}
              alt=""
              className="filter-theme mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title">Personalized Email Listserv</h4>
          </div>
          <p className="features-description mrg-tb24">
            Receive notifications for matching events with MyndFull's
            personalized email listserv customized by you.
          </p>
        </div>
      )}
    </>
  );
};

export default FindEventsCommunityAndEmail;
