import ExploreSpeakerS1 from './ExploreSpeakerS1';

const ExploreFeaturesSpeaker = ({ currentIndex }) => {
  return (
    <div className="features__wrapper">
      <ExploreSpeakerS1 currentIndex={currentIndex} />
    </div>
  );
};

export default ExploreFeaturesSpeaker;
