//utils
import { image_community } from '../../assets/images/explore';

const ExploreCommunity = ({ ExploreMyndFull }) => {
  return (
    <div className="explore-community bg-color-white">
      <img src={image_community} alt="" className="explore-community-img" />
      <div className="explore-community__container">
        <p className="explore-community-text-box">
          The absence of a dedicated educational event ecosystem is stifling. At
          stake is not only great ideas but also great communities. Exchanging
          ideas, perspectives and knowledge is a powerful way to build
          meaningful and lasting connections.
        </p>
      </div>
    </div>
  );
};

export default ExploreCommunity;
