import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageAccount from '../../../../language/features/useLanguageAccount';

//components
import DistanceInput from '../../../../components/DistanceInput/DistanceInput';
import AutocompletePlacesNoAddress from '../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import MapContainerWithDistanceFilter from '../../../../components/MapContainer/MapContainerWithDistanceFilter';
import SelectionsModal from '../../../../components/Selections/SelectionsModal';

//utils
import {
  img_building3,
  img_country,
  img_location,
  img_virtual,
} from '../../../../assets/icons';
import GetFlag from '../../../../utils/images/GetFlag';

const FindEventsEventType = ({ currentIndex }) => {
  //hooks
  const { CountryISOCodesSupported } = useLanguageData();
  const { width } = useSettings();

  //language
  const { IconAlts } = useLanguageComponents();
  const { Account } = useLanguageAccount();

  //demo data
  const [coords, setCoords] = useState([0, 0]);
  const [distanceFilter, setDistanceFilter] = useState(3);
  const [distanceMeasurement, setDistanceMeasurement] = useState('km');
  const [eventSearchOnlineCountries, setEventSearchOnlineCountries] = useState(
    []
  );
  //initialize
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  function setAutocompleteCoordinates(newCoordinates) {
    let newCoords = [newCoordinates.lat, newCoordinates.lng];
    setCoords(newCoords);
    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
  }

  return (
    <>
      {((width > 1280 && currentIndex === 1) ||
        (width <= 1280 && currentIndex === 1)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={img_building3}
              alt={IconAlts.iconInPerson}
              style={{ height: '32px' }}
              className="filter-theme mrg-r12"
            />
            <h4 className="features-title">In-Person Events</h4>
          </div>
          <p className="features-description mrg-tb24">
            In-Person Event searches are based on your account distance filter.
          </p>

          {coords && (
            <MapContainerWithDistanceFilter
              loadTrigger={true}
              loadValue={coords}
              handler={setCoords}
              distanceFilter={distanceFilter}
              distanceMeasurement={distanceMeasurement}
            />
          )}
          <div className="h24" />

          <AutocompletePlacesNoAddress
            setAutocompleteCoordinates={setAutocompleteCoordinates}
          />
          <div className="h24" />

          {isLoaded && (
            <div className="pos-rel">
              <DistanceInput
                distanceFilter={distanceFilter}
                setDistanceFilter={setDistanceFilter}
                distanceMeasurement={distanceMeasurement}
                setDistanceMeasurement={setDistanceMeasurement}
              />
              <div className="h24" />
            </div>
          )}
        </div>
      )}

      {((width > 1280 && currentIndex === 1) ||
        (width <= 1280 && currentIndex === 2)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={img_virtual}
              alt={IconAlts.iconVirtual}
              style={{ height: '28px' }}
              className="filter-theme mrg-r12"
            />
            <h4 className="features-title">Virtual Events</h4>
          </div>
          <p className="features-description mrg-tb24">
            Ignore physical distance by searching for Virtual Events among your
            selected countries.
          </p>

          <SelectionsModal
            addButtonText={Account.attendee.addCountries}
            DataOpts={CountryISOCodesSupported}
            fullWidth={true}
            iconModal={img_country}
            iconModalStyle={{ height: '24px' }}
            iconModalClass={'filter-theme'}
            iconOff={true}
            modalTitle={Account.attendee.eventLimitOnlineSearches}
            selectionState={eventSearchOnlineCountries}
            setSelectionState={setEventSearchOnlineCountries}
            subTextOn={false}
            id={'locationCountrySelections'}
            itemButtonCustomWrapper={'item-button-full'}
            listItemIconsFunction={GetFlag}
          />
        </div>
      )}
    </>
  );
};

export default FindEventsEventType;
