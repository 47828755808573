//hooks
import { useApiStatus } from '../../../../context/ApiStatusProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import FindSpeakersQueryFilters from '../../../../features/main/organize/findSpeakers/QueryFilters/FindSpeakersQueryFilters';

//utils
import { img_find_speaker } from '../../../../assets/icons';

const ExploreOrganizeS2 = ({ currentIndex }) => {
  //hooks
  const { handleWarning } = useApiStatus();

  //language
  const { IconAlts } = useLanguageComponents();

  function handleDemoNotice() {
    handleWarning({
      id: Date.now(),
      message: 'unavailableDemo',
      origin: 'ExploreOrganizeS2',
    });
  }

  return (
    <>
      {currentIndex === 1 && (
        <div className="features__container">
          <div className="pos-rel flex-row align-center mrg-t24">
            <img
              src={img_find_speaker}
              alt={IconAlts.iconSpeaker}
              className="mrg-r12"
              style={{ height: '42px' }}
            />
            <h4 className="features-title">Find Speakers</h4>
          </div>
          <p className="features-description mrg-t24">
            Load your event data or provide custom settings in our Find Speakers
            Tool to generate a list of matching Speakers. Request a Speaker's
            contact information and add them to the event directly.
          </p>
          <p className="mrg-tb36 fs21 color-gray full-width text-center">
            DEMO
          </p>
          <FindSpeakersQueryFilters
            handleFindSpeakersQuery={handleDemoNotice}
            handleViewSpeakerFromModalSelection={handleDemoNotice}
            queryIsLoading={false}
            setViewSpeaker={() => function () {}}
            setPaginatedData={() => function () {}}
            demo={true}
            handleDemoNotice={handleDemoNotice}
          />
        </div>
      )}
    </>
  );
};

export default ExploreOrganizeS2;
