//components
import FindEventsCommunityAndEmail from './FindEventsCommunityAndEmail';
import FindEventsEventType from './FindEventsEventType';
import FindEventsTags from './FindEventsTags';
import FindEventsToolbar from './FindEventsToolbar';

const ExploreFeaturesFindEvents = ({ currentIndex }) => {
  return (
    <div className="features__wrapper">
      {currentIndex === 0 && <FindEventsToolbar />}
      <FindEventsEventType currentIndex={currentIndex} />
      <FindEventsTags currentIndex={currentIndex} />
      <FindEventsCommunityAndEmail currentIndex={currentIndex} />
    </div>
  );
};

export default ExploreFeaturesFindEvents;
