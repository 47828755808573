import ExploreOrganizeS1 from './ExploreOrganizeS1';
import ExploreOrganizeS2 from './ExploreOrganizeS2';
import ExploreOrganizeS3 from './ExploreOrganizeS3';

const ExploreFeaturesOrganize = ({ currentIndex }) => {
  //hooks
  return (
    <div className="features__wrapper">
      <ExploreOrganizeS1 currentIndex={currentIndex} />
      <ExploreOrganizeS2 currentIndex={currentIndex} />
      <ExploreOrganizeS3 currentIndex={currentIndex} />
    </div>
  );
};

export default ExploreFeaturesOrganize;
