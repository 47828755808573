//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//utils
import {
  exchange,
  img_checklist,
  img_find_speaker,
  img_messages2,
  img_plus,
  lock_locked,
  preview,
  save,
} from '../../../../assets/icons';

const ExploreOrganizeS1 = ({ currentIndex }) => {
  //language
  const { IconAlts } = useLanguageComponents();

  return (
    <>
      {currentIndex === 0 && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={img_plus}
              alt="+"
              className="filter-theme mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title">Create Events</h4>
          </div>
          <div className="features-description mrg-tb24">
            <p>
              MyndFull's event creation is truly innovative. It packs powerful
              features to build academic and educational events while remaining
              simple and easy.
            </p>

            <ul className="features-checklist">
              <li className="align-center mrg-t24">
                <img
                  src={save}
                  alt={IconAlts.iconSave}
                  className="filter-theme mrg-r12"
                />
                <p>Restore, Save and Load Event Drafts</p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={preview}
                  alt={IconAlts.iconView}
                  className="filter-theme mrg-r12"
                />
                <p>Preview your Event before publishing or saving changes</p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={img_messages2}
                  alt={IconAlts.iconEmail}
                  className="filter-theme mrg-r12"
                />
                <p>
                  Request profile data from your Event Participants directly
                  through our built-in email service
                </p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={img_find_speaker}
                  alt={IconAlts.iconSpeaker}
                  className="mrg-r12"
                />
                <p>
                  Use our Find Speakers tool to find potential speakers with
                  expertise that match your event
                </p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={lock_locked}
                  alt={IconAlts.iconLock}
                  className="filter-theme mrg-r12"
                />
                <p>Keep event details private from non-registered attendees</p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={exchange}
                  alt={IconAlts.iconArrow}
                  className="filter-theme mrg-r12"
                />
                <p>
                  Integrate MyndFull's Real-time Translations tool into your
                  event to overcome language barriers
                </p>
              </li>
              <li className="align-center mrg-t24">
                <img
                  src={img_checklist}
                  alt={IconAlts.iconChecklist}
                  className="filter-theme mrg-r12"
                />
                <p>Build your event on one easy-to-navigate screen</p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
export default ExploreOrganizeS1;
