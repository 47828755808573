import { useEffect, useRef, useState } from 'react';
import { useSettings } from '../../context/SettingsProvider';

//utils
import {
  image_demo_mobile,
  image_demo_desktop,
} from '../../assets/images/explore';

const ExploreHeading = ({
  ExploreMyndFull,
  setExploreHeadingIsInitialized,
}) => {
  //hooks
  const { width } = useSettings();

  //state
  const [backgroundHeight, setBackgroundHeight] = useState(780);

  //variables
  const exploreHeading = document.getElementById('exploreHeading');
  const exploreSignUp = document.getElementById('exploreSignUp');
  const exploreImgs = document.getElementById('exploreImgs');

  const initializeTimer = useRef();

  useEffect(() => {
    initializeTimer.current = setTimeout(() => {
      setExploreHeadingIsInitialized(true);
    }, 1000);
    return () => clearTimeout(initializeTimer.current);
  }, []);

  useEffect(() => {
    if (exploreHeading && exploreSignUp && exploreImgs) {
      adjustHeight();
    }
  }, [width, exploreHeading, exploreSignUp, exploreImgs]); //sets height for heading background so demo images hang over.

  function adjustHeight() {
    if (exploreHeading && exploreSignUp && exploreImgs) {
      const exploreHeadingHeight = exploreHeading.clientHeight;
      const exploreSignUpHeight = exploreSignUp.clientHeight;
      const exploreImgsHeight = exploreImgs.clientHeight;

      const combinedHeight =
        exploreHeadingHeight + exploreSignUpHeight + exploreImgsHeight;

      const calculatedHeight = combinedHeight * 0.9;

      setBackgroundHeight(calculatedHeight);
    }
  }

  return (
    <div
      className="explore-heading bg-radial-light-theme"
      style={{ minHeight: `${backgroundHeight}px` }}
    >
      <h1 className="color-darkgray pad-t48" id="exploreHeading">
        {ExploreMyndFull.exploreHeading1} <br />
        {ExploreMyndFull.exploreHeading2}
      </h1>
      <div className="explore-signUp" id="exploreSignUp">
        <button className="explore-button">{ExploreMyndFull.signUp}</button>
      </div>

      <div className="explore-heading-img__wrapper" id="exploreImgs">
        <img
          src={image_demo_mobile}
          alt={ExploreMyndFull.demoImgMobile}
          className="explore-header-img--mobile"
        />
        <img
          src={image_demo_desktop}
          alt={ExploreMyndFull.demoImgDesktop}
          className="explore-header-img--desktop"
        />
      </div>
    </div>
  );
};

export default ExploreHeading;
