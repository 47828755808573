import { img_social, speaker_dark } from '../../../../assets/icons';
import { useSettings } from '../../../../context/SettingsProvider';
import useLanguageComponents from '../../../../language/useLanguageComponents';

const ExploreSpeakerS1 = ({ currentIndex }) => {
  //hooks
  const { width } = useSettings();

  //language
  const { IconAlts } = useLanguageComponents();

  return (
    <>
      {((width > 1280 && currentIndex === 0) ||
        (width <= 1280 && currentIndex === 0)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={speaker_dark}
              alt={IconAlts.iconSpeaker}
              className="filter-theme mrg-r12"
              style={{ height: '38px' }}
            />
            <h4 className="features-title">Become a Speaker</h4>
          </div>
          <p className="features-description mrg-tb24">
            Create a Speaker Profile and allow Organizers to find your profile
            based on your location, language and tags. Your contact info remains
            private until you accept a Contact Request.
          </p>
        </div>
      )}

      {((width > 1280 && currentIndex === 1) ||
        (width <= 1280 && currentIndex === 1)) && (
        <div className="features__container">
          <div className="flex-row align-center mrg-t24">
            <img
              src={img_social}
              alt={IconAlts.iconCommunity}
              className="filter-theme mrg-r12"
              style={{ height: '28px' }}
            />
            <h4 className="features-title">Build the Community</h4>
          </div>
          <p className="features-description mrg-tb24">
            Enable Follow Mode so Attendees can follow you and see the events
            that you participate in as a Speaker.
          </p>
        </div>
      )}
    </>
  );
};

export default ExploreSpeakerS1;
