import { useState, useEffect } from 'react';

//Hooks
import { useGenericModal } from '../../context/GenericModalProvider';
import { useSettings } from '../../context/SettingsProvider';
import { useApiStatus } from '../../context/ApiStatusProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Components
import TinyTagGroups from './TinyTagGroups';
// import TinyTagSuggestionsModal from '../Modal/ModalComponents/ListModals/TinyTagsSuggestionModal';

//Utility
import { tinyTagStringValidator } from '../../utils/misc/tinyTagsValidator';
import { cancel } from '../../assets/icons';

const CustomTagsList = ({
  // bigTags,
  customWrapper,
  id,
  limit,
  setTinyTagGroups,
  setTinyTagGroupsModifyActive,
  setTinyTagGroupsSaveValid,
  setTinyTags,
  tagGroupsOff,
  tinyTagGroups,
  tinyTags,
  updateAccountIsSuccess,
}) => {
  //Hooks
  const { handleWarning } = useApiStatus();
  const {
    // setGenericModal,
    genericModalSelectionState,
    genericModal,
    // setGenericModalSelectionStateId,
    genericModalSelectionStateId,
    setGenericModalSelectionState,
    invalidSelection,
    setInvalidSelection,
  } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //language
  const { Generic, CustomTagsList } = useLanguageComponents();

  //Component state
  const [modifyTagGroup, setModifyTagGroup] = useState();
  const [tagsCount, setTagsCount] = useState(0);

  //UI state
  const [inputField, setInputField] = useState();
  // const [tapHighlightSeeSuggestions, setTapHighlightSeeSuggestions] =
  //   useState(false);
  const [tapHighlightTag, setTapHighlightTag] = useState();

  //Initialize functions
  useEffect(() => {
    setInputField(document.getElementById(`${id}-input`));
  }, []); //if draft is loaded in createEvent and user uses input as first action, will not find value.

  //UI functions
  function handleTagsClick(e, tinyTags) {
    if (!modifyTagGroup) {
      handleMobileTap(
        [() => setTapHighlightTag(e.target.id), () => setTapHighlightTag()],
        [() => handleRemoveItem(e, tinyTags)]
      );
    } else {
      handleMobileTap(
        [() => setTapHighlightTag(e.target.id), () => setTapHighlightTag()],
        [
          () => handleRemoveItem(e, tinyTags),
          () => handleTagGroupTagAdd(e.target.id),
        ]
      );
    }
  }

  //Component functions
  useEffect(() => {
    let tags = 0;

    if (!tagGroupsOff) {
      tinyTagGroups?.map((tG) => {
        tags += tG.tags.length;
      });
    }

    if (tags === 0 || tagsCount !== tags) {
      let tagsNum = tags + tinyTags.length;
      setTagsCount(tagsNum);
    }

    /*eslint-disable-next-line*/
  }, [tinyTags, tinyTagGroups]);

  function addTag(tag) {
    const validString = tinyTagStringValidator(tag);

    if (!validString) {
      inputField.value = '';
      return handleWarning({
        message: 'invalidTinyTag',
        statusCode: 500,
        origin: 'CustomTagsList/addTag',
      });
    }

    //tag argument from tinyTagGroups
    let newTag = ('#' + inputField.value).trim().toLocaleLowerCase();

    if (newTag === '#') {
      return null;
    }

    let tagExistsCheck = false;

    if (tinyTags.includes(newTag)) {
      tagExistsCheck = true;
    }

    if (!tagGroupsOff) {
      if (tinyTagGroups.length > 0) {
        tinyTagGroups?.map((tG) => {
          if (tG.tags?.length > 0) {
            if (tG.tags.includes(newTag)) {
              return (tagExistsCheck = true);
            }
          }
          return null;
        });
      }
    }

    if (tagExistsCheck) {
      inputField.value = '';
      handleWarning({
        message: 'tagAlreadySelected',
        origin: 'CustomTagsList.js/addTag',
        id: Date.now(),
      });
    } else {
      if (!limit || tinyTags.length < limit) {
        setTinyTags([...tinyTags, newTag]);
        inputField.value = '';
      } else {
        return handleWarning({
          message: 'tagLimitReached',
          origin: 'CustomTagsList.js/addTag',
          id: Date.now(),
        });
      }
    }
  }

  function transferTag(tag) {
    setTinyTags([...tinyTags, tag]);
  }

  function handleKeyDown(e) {
    if (
      e.code === 'Enter' ||
      e.code === 'NumpadEnter' ||
      e.key === 'Enter' ||
      e.key === 'Return' ||
      e.code === 'Return'
    ) {
      e.preventDefault();
      addTag(e.target.value);
    } else {
      const key = e.key;
      const regex = /[a-zA-Z0-9]/;
      if (!regex.test(key)) {
        e.preventDefault();
      }
    }
  }

  function handleRemoveItem(e, tinyTags) {
    let newArr = [...tinyTags];
    let valueToRemove = [newArr[e.target.name]];
    newArr = newArr.filter((element) => !valueToRemove.includes(element));
    setTinyTags(newArr);
  }

  //////////Tag Groups
  function handleTagGroupTagAdd(tag) {
    const newTagGroup = [...tinyTagGroups];
    const adjustedTagGroup = newTagGroup?.map((tG) => {
      if (modifyTagGroup === tG.id) {
        return {
          ...tG,
          tags: [...tG.tags, tag],
        };
      } else {
        return tG;
      }
    });
    setTinyTagGroups(adjustedTagGroup);
  }

  //suggestions list
  // function handleSeeSuggestionsList() {
  //   setGenericModalSelectionStateId(id);

  //   setGenericModal(
  //     <TinyTagSuggestionsModal
  //       tinyTags={tinyTags}
  //       tinyTagGroups={tinyTagGroups}
  //       handleAddTags={handleAddMultipleTags}
  //       limit={limit}
  //       bigTags={bigTags}
  //       CustomTagsList={CustomTagsList}
  //     />
  //   );
  // }

  function handleAddMultipleTags(tags) {
    if (invalidSelection) return setInvalidSelection(false);
    if (tags?.length + tinyTags?.length > limit) return;

    inputField.value = '';
    let prevTagsArr = [...tinyTags];
    let newTagsArr = [];

    tags?.map((tag) => {
      if (!prevTagsArr.includes(tag)) {
        return newTagsArr.push(tag);
      }
    });

    newTagsArr = [...newTagsArr, ...prevTagsArr];
    newTagsArr.sort().reverse();
    setTinyTags(newTagsArr);
  }

  //modal functionality
  useEffect(() => {
    if (
      genericModalSelectionState?.length > 0 &&
      !genericModal &&
      genericModalSelectionStateId === id
    ) {
      handleAddMultipleTags(genericModalSelectionState);
      setGenericModalSelectionState();
    }
  }, [genericModal, genericModalSelectionState]);

  return (
    <div
      className={`custom-tags-list__wrapper no-select ${
        customWrapper ? customWrapper : ''
      }`}
    >
      <textarea
        autoComplete="off"
        name={id}
        id={`${id}-input`}
        className="custom-tags-input fs18 fwn"
        maxLength="60"
        type="text"
        onKeyDown={(e) => handleKeyDown(e)}
        aria-label={CustomTagsList.inputInstructions + limit}
        enterKeyHint="enter"
      />
      <p className="dropdown__hash" aria-hidden="true">
        #
      </p>

      {/* Input Tags (No group) */}
      <div className="dropdown-tags__wrapper">
        <div className="flex-row space-between full-width flex-center mrg-t12">
          {limit && (
            <div className="flex-row">
              <p
                className={`access-ob access-o6 no-select fs16 fwn`}
                tabIndex="0"
              >
                {tagsCount} / {limit}
              </p>
            </div>
          )}
          {/* <button
            className={`fs16 highlight-t-theme access-ob access-o6 ${
              tapHighlightSeeSuggestions ? 'highlight-t-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightSeeSuggestions(true),
                  () => setTapHighlightSeeSuggestions(false),
                ],
                [() => handleSeeSuggestionsList()]
              )
            }
            id={'suggestionsListBtn'}
          >
            {CustomTagsList.seeSuggestions}
          </button> */}
        </div>

        {tagsCount > limit && (
          <div className="banners__notice align-center mrg-auto-lr fs16 fwsb mrg-t24">
            {CustomTagsList.tagsLimitExceeded}
          </div>
        )}

        {tinyTags?.length > 0 && <div className="h12" />}
        <ul className="tags__container mrg-t12">
          {tinyTags
            ?.map((tag, index) => (
              <li key={`${tag}-${index}`}>
                <button
                  tabIndex="0"
                  className={`tag__list-item highlight-bthin-theme fwsb fs16 fs14-phn no-select access-ob access-o6 ${
                    modifyTagGroup
                      ? 'tag__list-item--add highlight-i-lgg'
                      : 'highlight-i-lgr'
                  } ${tapHighlightTag === tag ? 'highlight-b-theme--tap' : ''}`}
                  aria-label={`${tag} ${
                    modifyTagGroup ? CustomTagsList.ariaAddTag : Generic.remove
                  } `}
                  name={index} //keep for removal
                  id={tag}
                  onClick={(e) => handleTagsClick(e, tinyTags)}
                >
                  <p>{tag}</p>

                  <img
                    alt={'X'}
                    className={`disable-select tag__list-item--remove-btn mrg-l6 ${
                      modifyTagGroup ? 'add-tag rotate-cw45' : ''
                    }`}
                    src={cancel}
                  />
                </button>
              </li>
            ))
            .reverse()}
        </ul>
        {!tagGroupsOff && (
          <TinyTagGroups
            tinyTags={tinyTags}
            setTinyTags={setTinyTags}
            tinyTagGroups={tinyTagGroups}
            setTinyTagGroups={setTinyTagGroups}
            modifyTagGroup={modifyTagGroup}
            setModifyTagGroup={setModifyTagGroup}
            setTinyTagGroupsSaveValid={setTinyTagGroupsSaveValid}
            transferTag={transferTag}
            setTinyTagGroupsModifyActive={setTinyTagGroupsModifyActive}
            updateAccountIsSuccess={updateAccountIsSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default CustomTagsList;
