import { useEffect, useState } from 'react';

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageData from '../../../../language/useLanguageData';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

//Utility
import { folder } from '../../../../assets/icons/index';

const DemoTagsList = ({
  view,
  ////
  demoBigTags,
  demoTinyTags,
  demoTinyTagGroups,
  demoCommunityFollowing,
  demoCommunityFollowingGroups,
  ////
  //big tags
  selectedBigTags,
  selectAllBTBtn,
  removeSelectedBTs,
  addSelectedBTs,
  removeAllSelectedBTs,
  setSelectAllBTBtn,
  //my events
  selectedMyEvents,
  selectAllMyEventsBtn,
  setSelectedMyEvents,
  setSelectAllMyEventsBtn,
  //Tiny Tags
  selectedTinyTags,
  selectedTinyTagGroupIds,
  selectAllTTBtn,
  removeSelectedTinyTags,
  addSelectedTinyTags,
  setSelectAllTTBtn,
  removeAllSelectedTinyTags,
  removeSelectedTinyTagGroupIds,
  addSelectedTinyTagGroupIds,
  removeAllSelectedTinyTagGroupIds,
  //community
  selectAllAccountsFollowingBtn,
  setSelectAllAccountsFollowingBtn,
  selectedFollowingIds,
  selectedFollowingGroupIds,
  removeAllSelectedAccountsFollowingIds,
  removeSelectedAccountsFollowingId,
  addSelectedAccountsFollowingId,
  removeSelectedAccountsFollowingGroupIds,
  addSelectedAccountsFollowingGroupIds,
  removeAllSelectedAccountsFollowingGroupIds,
}) => {
  //Hooks
  const { width } = useSettings();

  //language
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { IconAlts } = useLanguageComponents();
  const { BigTagsOpts } = useLanguageData();

  //Component state
  const [showAllTags, setShowAllTags] = useState(false);

  //UI functions
  useEffect(() => {
    setShowAllTags(false);
  }, [view]);

  //Component funcitons
  function handleMyEventsClick(key) {
    if (selectedMyEvents?.includes(key)) {
      let newArr = selectedMyEvents?.filter((x) => x !== key);
      setSelectedMyEvents(newArr);
    } else {
      let newArr = [...selectedMyEvents, key];
      setSelectedMyEvents(newArr);
    }
  }

  useEffect(() => {
    if (selectedMyEvents?.length === 0 && selectAllMyEventsBtn) {
      setSelectAllMyEventsBtn(false);
    }

    if (selectedMyEvents?.length === 3 && !selectAllMyEventsBtn) {
      setSelectAllMyEventsBtn(true);
    }

    /*eslint-disable-next-line*/
  }, [selectedMyEvents]);

  function handleSelectAll(selectAll) {
    if (view === 'myevents') {
      if (selectAll) {
        setSelectedMyEvents(['registered', 'saved', 'participationEvents']);
      } else if (!selectAll) {
        setSelectedMyEvents([]);
      }
      setSelectAllMyEventsBtn(selectAll);
    }

    if (view === 'bigtags') {
      if (selectAll) {
        removeAllSelectedBTs();
      } else if (!selectAll) {
        addSelectedBTs('addAll');
      } //different than redux version;
      setSelectAllBTBtn(!selectAll);
    }

    if (view === 'tinytags') {
      if (selectAll) {
        removeAllSelectedTinyTags();
        removeAllSelectedTinyTagGroupIds();
      } else if (!selectAll) {
        addSelectedTinyTags('addAll');
        addSelectedTinyTagGroupIds('addAll');
      } //different than redux version;

      setSelectAllTTBtn(!selectAll);
    }

    if (view === 'community') {
      if (selectAll) {
        removeAllSelectedAccountsFollowingIds();
        removeAllSelectedAccountsFollowingGroupIds();
      } else if (!selectAll) {
        addSelectedAccountsFollowingId('addAll');
        addSelectedAccountsFollowingGroupIds('addAll');
      }
      setSelectAllAccountsFollowingBtn(!selectAll);
    }
  }

  //Big Tags
  function handleBigTag(bigTag) {
    if (selectedBigTags.includes(bigTag)) {
      removeSelectedBTs(bigTag);
    } else {
      addSelectedBTs(bigTag);
    }
  }

  useEffect(() => {
    if (selectedBigTags?.length === 0 && selectAllBTBtn) {
      setSelectAllBTBtn(!selectAllBTBtn);
    }
    if (selectedBigTags?.length === demoBigTags?.length && !selectAllBTBtn) {
      setSelectAllBTBtn(!selectAllBTBtn);
    }
    /*eslint-disable-next-line*/
  }, [selectedBigTags]); //bT select all when selecting or unselecting all tags

  //Tiny Tags
  function handleTinyTag(tinyTag) {
    if (selectedTinyTags.includes(tinyTag)) {
      removeSelectedTinyTags(tinyTag);
    } else {
      addSelectedTinyTags(tinyTag);
    }
  }

  function handleTinyTagGroup(tinyTagGroupId) {
    if (selectedTinyTagGroupIds.includes(tinyTagGroupId)) {
      removeSelectedTinyTagGroupIds(tinyTagGroupId);
    } else {
      addSelectedTinyTagGroupIds(tinyTagGroupId);
    }
  }

  useEffect(() => {
    if (
      selectedTinyTags?.length === 0 &&
      selectedTinyTagGroupIds?.length === 0 &&
      selectAllTTBtn
    ) {
      setSelectAllTTBtn(!selectAllTTBtn);
    }
    if (
      selectedTinyTags?.length === demoTinyTags?.length &&
      selectedTinyTagGroupIds?.length === demoTinyTagGroups?.length &&
      !selectAllTTBtn
    ) {
      setSelectAllTTBtn(!selectAllTTBtn);
    }
    /*eslint-disable-next-line*/
  }, [selectedTinyTags, selectedTinyTagGroupIds]); //tT select all when selecting or unselecting all tags

  //Community
  function handleCommunityAccount(accountId) {
    if (selectedFollowingIds.includes(accountId)) {
      removeSelectedAccountsFollowingId(accountId);
    } else {
      addSelectedAccountsFollowingId(accountId);
    }
  }

  function handleAccountsFollowingGroup(accountsFollowingGroupId) {
    if (selectedFollowingGroupIds.includes(accountsFollowingGroupId)) {
      removeSelectedAccountsFollowingGroupIds(accountsFollowingGroupId);
    } else {
      addSelectedAccountsFollowingGroupIds(accountsFollowingGroupId);
    }
  }

  useEffect(() => {
    if (
      selectedFollowingIds?.length === 0 &&
      selectedFollowingGroupIds?.length === 0 &&
      selectAllAccountsFollowingBtn
    ) {
      setSelectAllAccountsFollowingBtn(!selectAllAccountsFollowingBtn);
    }
    if (
      selectedFollowingIds?.length === demoCommunityFollowing?.length &&
      selectedFollowingGroupIds?.length ===
        demoCommunityFollowingGroups?.length &&
      !selectAllAccountsFollowingBtn
    ) {
      setSelectAllAccountsFollowingBtn(!selectAllAccountsFollowingBtn);
    }
    /*eslint-disable-next-line*/
  }, [selectedFollowingIds, selectedFollowingGroupIds]); //community select all when selecting or unselecting all tags

  return (
    <>
      {width < 775 && (
        <p className="fs16 fwsb mrg-b12 color-black">
          {view === 'myevents'
            ? SettingsEventsLanguage.myEvents
            : SettingsEventsLanguage.tags}
        </p>
      )}
      <div
        className={`settings-tags__wrapper no-mrg-lr full-width`}
        id="tags__wrapper"
      >
        {view === 'myevents' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="settings-tags-container"
          >
            <li key={'select-all-myEvents-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 pad-lr12 access-ob access-o6 ${
                  selectAllMyEventsBtn ? 'selected' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectAll(!selectAllMyEventsBtn);
                }}
              >
                {selectAllMyEventsBtn ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            <li key={'registeredEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 
                  ${
                    selectedMyEvents?.includes('registered') ? 'selected' : ''
                  }`}
                key={'registeredEvents-btn'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMyEventsClick('registered');
                }}
                aria-pressed={selectedMyEvents?.includes('registered')}
              >
                <p>{SettingsEventsLanguage.registered}</p>
              </button>
            </li>
            <li key={'savedEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                  selectedMyEvents?.includes('saved') ? 'selected' : ''
                }`}
                key={'savedEvents-btn'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMyEventsClick('saved');
                }}
                aria-pressed={selectedMyEvents?.includes('saved')}
              >
                <p>{SettingsEventsLanguage.saved}</p>
              </button>
            </li>
            <li key={'participationEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                  selectedMyEvents?.includes('participationEvents')
                    ? 'selected'
                    : ''
                }`}
                key={'participationEvents-btn'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMyEventsClick('participationEvents');
                }}
                aria-pressed={selectedMyEvents?.includes('participationEvents')}
              >
                <p>{SettingsEventsLanguage.participation}</p>
              </button>
            </li>
          </ul>
        )}
        {view === 'bigtags' && (
          <div className="flex-column full-width">
            <ul
              className="settings-tags__container access-ob access-o6"
              tabIndex="0"
              aria-label={SettingsEventsLanguage.ariaEventFilters}
              id="tags-container"
            >
              <li key={'select-all-bigtag-li'}>
                <button
                  className={`settings-tag ff2 color-theme mrg-r12 pad-8 pad-lr12 access-ob access-o6 ${
                    selectAllBTBtn ? 'selected' : ''
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectAll(selectAllBTBtn);
                  }}
                >
                  {selectAllBTBtn ? (
                    <p>{SettingsEventsLanguage.unselectAll}</p>
                  ) : (
                    <p>{SettingsEventsLanguage.selectAll}</p>
                  )}
                </button>
              </li>
              {view === 'bigtags' && demoBigTags && (
                <>
                  {[...demoBigTags].reverse()?.map((bT, index) => {
                    if (width > 1280 || index < 5 || showAllTags) {
                      return (
                        <li key={`${bT}-li`}>
                          <button
                            className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                              selectedBigTags.includes(bT) ? 'selected' : ''
                            }`}
                            key={bT}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleBigTag(bT);
                            }}
                            aria-pressed={selectedBigTags.includes(bT)}
                            aria-label={`${SettingsEventsLanguage.ariaTag} ${BigTagsOpts[bT]}.`}
                          >
                            <p>{BigTagsOpts[bT]}</p>
                          </button>
                        </li>
                      );
                    }
                    return null;
                  })}

                  {!showAllTags && width < 1280 && demoBigTags?.length > 5 && (
                    <li key={'show-more-bigtag-li'}>
                      <button
                        className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                          true ? 'selected' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAllTags(true);
                        }}
                        aria-label={
                          SettingsEventsLanguage.ariaShowRemainingTags
                        }
                      >
                        <p> . . . ({demoBigTags?.length - 5})</p>
                      </button>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        )}

        {view === 'tinytags' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="tags-container"
          >
            <li key={'select-all-tinytag-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 pad-lr12 access-ob access-o6 ${
                  selectAllTTBtn ? 'selected' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectAll(selectAllTTBtn);
                }}
              >
                {selectAllTTBtn ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            {view === 'tinytags' && demoTinyTagGroups && (
              <>
                {[...demoTinyTagGroups].reverse()?.map((tG, index) => {
                  if (width > 1280 || index < 5 || showAllTags) {
                    return (
                      <li key={`${tG.label}-li`}>
                        <button
                          className={`settings-tag ff2 color-theme mrg-r12 pad-8 align-center access-ob access-o6 ${
                            selectedTinyTagGroupIds.includes(tG.id)
                              ? 'selected'
                              : ''
                          }`}
                          key={tG.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTinyTagGroup(tG.id);
                          }}
                          aria-pressed={selectedTinyTagGroupIds.includes(tG.id)}
                          aria-label={`${SettingsEventsLanguage.ariaTinyTagGroup} ${tG.label}.`}
                        >
                          <img
                            src={folder}
                            alt={IconAlts.iconFolder}
                            className="filter-theme folder-img"
                          />
                          <p>{tG.label}</p>
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}

                {demoTinyTags?.map((tT, index) => {
                  if (width > 1280 || index < 5 || showAllTags) {
                    return (
                      <li key={`${tT}-li`}>
                        <button
                          className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                            selectedTinyTags.includes(tT) ? 'selected' : ''
                          }`}
                          key={tT}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTinyTag(tT);
                          }}
                          aria-pressed={selectedTinyTags.includes(tT)}
                          aria-label={`${SettingsEventsLanguage.ariaTag} ${tT}.`}
                        >
                          <p>{tT}</p>
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                {!showAllTags && width < 1280 && demoTinyTags?.length > 5 && (
                  <li key="show-more-tinytag-li">
                    <button
                      className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                        true ? 'selected' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowAllTags(true);
                      }}
                      aria-label={SettingsEventsLanguage.ariaShowRemainingTags}
                    >
                      <p>. . . ({demoTinyTags?.length - 5})</p>
                    </button>
                  </li>
                )}
              </>
            )}
          </ul>
        )}

        {view === 'community' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="tags-container"
          >
            <li key={'select-all-community-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 pad-lr12 access-ob access-o6 ${
                  selectAllAccountsFollowingBtn ? 'selected' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectAll(selectAllAccountsFollowingBtn);
                }}
              >
                {selectAllAccountsFollowingBtn ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            {[...demoCommunityFollowingGroups]
              .reverse()
              ?.map((groupObj, index) => {
                if (width > 1280 || index < 5 || showAllTags) {
                  return (
                    <li key={`${groupObj.label}-li`}>
                      <button
                        className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                          selectedFollowingGroupIds?.includes(groupObj.id)
                            ? 'selected'
                            : ''
                        }`}
                        key={groupObj.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAccountsFollowingGroup(groupObj.id);
                        }}
                        aria-pressed={selectedFollowingGroupIds?.includes(
                          groupObj.id
                        )}
                      >
                        <img
                          src={folder}
                          alt={IconAlts.iconFolder}
                          className="filter-theme folder-img"
                        />
                        <p>{groupObj.label}</p>
                      </button>
                    </li>
                  );
                }
                return null;
              })}
            {view === 'community' && demoCommunityFollowing && (
              <>
                {[...demoCommunityFollowing]
                  .reverse()
                  ?.map((accountFollowingObj, index) => {
                    if (width > 1280 || index < 5 || showAllTags) {
                      return (
                        <li key={`accountFollowing-li-${index}`}>
                          <button
                            className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                              selectedFollowingIds.includes(
                                accountFollowingObj._id
                              )
                                ? 'selected'
                                : ''
                            }`}
                            key={`account-${accountFollowingObj?.personal?.profileImage}-${index}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCommunityAccount(accountFollowingObj._id);
                            }}
                            aria-pressed={selectedFollowingIds.includes(
                              accountFollowingObj._id
                            )}
                          >
                            <LoadBucketImage
                              imagePath={
                                accountFollowingObj?.personal?.profileImage
                              }
                              imgClassName="tag-profile-img mrg-r6"
                              backupPath={'account_img_default.png'}
                              partialPath={'myndfull_account_images'}
                            />
                            <p>
                              {accountFollowingObj?.personal?.firstName}&nbsp;
                              {accountFollowingObj?.personal?.lastName}
                            </p>
                          </button>
                        </li>
                      );
                    }
                    return null;
                  })}
                {!showAllTags &&
                  width < 1280 &&
                  demoCommunityFollowing?.length > 5 && (
                    <li key={'show-more-accountfollowing-li'}>
                      <button
                        className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                          true ? 'selected' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAllTags(true);
                        }}
                        aria-label={
                          SettingsEventsLanguage.ariaShowRemainingTags
                        }
                      >
                        <p> . . . ({demoCommunityFollowing?.length - 5})</p>
                      </button>
                    </li>
                  )}
              </>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default DemoTagsList;
