//hooks
import { useSettings } from '../../context/SettingsProvider';

//utils
import { exchange } from '../../assets/icons';
import { image_podium_speaker } from '../../assets/images/explore';

const ExploreTranslations = () => {
  //hooks
  const { width } = useSettings();

  return (
    <div className="explore-myndfull-translations bg-light-blue">
      <div className="br--standard">
        {width <= 900 && (
          <div className="flex-row flex-center mrg-b24 text-center">
            <img className=" mrg-r12 filter-darkgray" src={exchange} alt="" />
            <h2>MyndFull Translations</h2>
          </div>
        )}
        <div className="flex-row">
          <div className="flex-column explore-myndfull-translations-img__container">
            <img
              src={image_podium_speaker}
              alt=""
              className="explore-myndfull-translations-img"
            />
            <button className="explore-button explore-button--translations mrg-t24 flex-center mrg-auto-lr">
              <div className="flex-column">
                <span>Tutorial</span>
                <span className="fs18 mrg-t6">(Coming Soon)</span>
              </div>
            </button>
          </div>

          {width > 900 && (
            <div className="flex-column mrg-l48">
              <div className="flex-row flex-center  mrg-b24">
                <img
                  className="mrg-r24 filter-darkgray"
                  src={exchange}
                  alt=""
                />
                <h2>MyndFull Translations</h2>
              </div>
              <div className="explore-base-text">
                <p>
                  Built for formal in-person presentations, MyndFull
                  Translations provides real-time translations through the
                  organizer's computer and the audience's phones. The organizer
                  sets up a room through their room code subscription, then
                  audience members can join the room with their personal
                  devices. Audience members do not require downloads or
                  accounts, and joining a room only requires a few easy clicks.
                </p>

                <p className="mrg-t24">
                  Users select their language and receive translated text and
                  audio based on microphone's captured speech. The results can
                  be described as a "United Nations" style of personal
                  translations &mdash; but the whole process is automated!
                  MyndFull Translations is based on a fully transparent pricing
                  system and self-serve setup.
                </p>
              </div>
            </div>
          )}
        </div>

        {width <= 900 && (
          <div className="flex-column explore-base-text mrg-t24">
            <p>
              Built for formal in-person presentations, MyndFull Translations
              provides real-time translations through the organizer's computer
              and the audience's phones. The organizer sets up a room through
              their room code subscription, then audience members can join the
              room with their personal devices. Audience members do not require
              downloads or accounts, and joining a room only requires a few easy
              clicks.
            </p>

            <p className="mrg-t24">
              Users select their language and receive translated text and audio
              based on microphone's captured speech. The results can be
              described as a "United Nations" style of personal translations
              &mdash; but the whole process is automated! MyndFull Translations
              is based on a fully transparent pricing system and self-serve
              setup.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExploreTranslations;
