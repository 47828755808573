//components
import DemoEventsViewsSummaryMobile from './DemoEventsViewsSummaryMobile';
import DemoSearchSummary from './DemoSearchSummary';
import DemoSettingsDekstop from './DemoSettingsDekstop';
import DemoSettingsMobile from './DemoSettingsMobile';
import DemoViews from './DemoViews';

const DemoEventSettings = ({
  view,
  setView,
  setToolbarActiveHighlight,
  toolbarActiveHighlight,
  searchDirection,
  setSearchDirection,
  searchFormat,
  setSearchFormat,
  searchCategory,
  setSearchCategory,
  settingsListOpened,
  setSettingsListOpened,
  demoBigTags,
  demoTinyTags,
  demoTinyTagGroups,
  demoCommunityFollowing,
  demoCommunityFollowingGroups,
  selectedMyEvents,
  selectedBigTags,
  selectedTinyTags,
  selectedTinyTagGroupIds,
  selectedFollowingIds,
  selectedFollowingGroupIds,
  width,
  selectAllBTBtn,
  removeSelectedBTs,
  addSelectedBTs,
  removeAllSelectedBTs,
  setSelectAllBTBtn,
  selectAllMyEventsBtn,
  setSelectedMyEvents,
  setSelectAllMyEventsBtn,
  selectAllTTBtn,
  removeSelectedTinyTags,
  addSelectedTinyTags,
  removeSelectedTinyTagGroupIds,
  addSelectedTinyTagGroupIds,
  removeAllSelectedTinyTagGroupIds,
  setSelectAllTTBtn,
  removeAllSelectedTinyTags,
  selectAllAccountsFollowingBtn,
  setSelectAllAccountsFollowingBtn,
  removeAllSelectedAccountsFollowingIds,
  removeSelectedAccountsFollowingId,
  addSelectedAccountsFollowingId,
  removeSelectedAccountsFollowingGroupIds,
  addSelectedAccountsFollowingGroupIds,
  removeAllSelectedAccountsFollowingGroupIds,
}) => {
  return (
    <>
      {width >= 775 ? (
        <DemoSettingsDekstop
          view={view}
          setView={setView}
          setToolbarActiveHighlight={setToolbarActiveHighlight}
          searchDirection={searchDirection}
          setSearchDirection={setSearchDirection}
          searchFormat={searchFormat}
          setSearchFormat={setSearchFormat}
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
          settingsListOpened={settingsListOpened}
          setSettingsListOpened={setSettingsListOpened}
          demoBigTags={demoBigTags}
          demoTinyTags={demoTinyTags}
          demoTinyTagGroups={demoTinyTagGroups}
          demoCommunityFollowing={demoCommunityFollowing}
          demoCommunityFollowingGroups={demoCommunityFollowingGroups}
          selectedMyEvents={selectedMyEvents}
          selectedBigTags={selectedBigTags}
          selectedTinyTags={selectedTinyTags}
          selectedTinyTagGroupIds={selectedTinyTagGroupIds}
          selectedFollowingIds={selectedFollowingIds}
          selectedFollowingGroupIds={selectedFollowingGroupIds}
          width={width}
          selectAllBTBtn={selectAllBTBtn}
          removeSelectedBTs={removeSelectedBTs}
          addSelectedBTs={addSelectedBTs}
          removeAllSelectedBTs={removeAllSelectedBTs}
          setSelectAllBTBtn={setSelectAllBTBtn}
          selectAllMyEventsBtn={selectAllMyEventsBtn}
          setSelectedMyEvents={setSelectedMyEvents}
          setSelectAllMyEventsBtn={setSelectAllMyEventsBtn}
          selectAllTTBtn={selectAllTTBtn}
          removeSelectedTinyTags={removeSelectedTinyTags}
          addSelectedTinyTags={addSelectedTinyTags}
          removeSelectedTinyTagGroupIds={removeSelectedTinyTagGroupIds}
          addSelectedTinyTagGroupIds={addSelectedTinyTagGroupIds}
          removeAllSelectedTinyTagGroupIds={removeAllSelectedTinyTagGroupIds}
          setSelectAllTTBtn={setSelectAllTTBtn}
          removeAllSelectedTinyTags={removeAllSelectedTinyTags}
          selectAllAccountsFollowingBtn={selectAllAccountsFollowingBtn}
          setSelectAllAccountsFollowingBtn={setSelectAllAccountsFollowingBtn}
          removeAllSelectedAccountsFollowingIds={
            removeAllSelectedAccountsFollowingIds
          }
          removeSelectedAccountsFollowingId={removeSelectedAccountsFollowingId}
          addSelectedAccountsFollowingId={addSelectedAccountsFollowingId}
          removeSelectedAccountsFollowingGroupIds={
            removeSelectedAccountsFollowingGroupIds
          }
          addSelectedAccountsFollowingGroupIds={
            addSelectedAccountsFollowingGroupIds
          }
          removeAllSelectedAccountsFollowingGroupIds={
            removeAllSelectedAccountsFollowingGroupIds
          }
        />
      ) : (
        <div className="search-sort--mobile no-select mrg-t12">
          <div
            className={`pad-t12 pad-b12 ${
              toolbarActiveHighlight === 'view'
                ? 'demo-toolbar-highlight--mobile'
                : ''
            }`}
          >
            <DemoViews
              view={view}
              setView={setView}
              setToolbarActiveHighlight={setToolbarActiveHighlight}
              width={width}
            />
          </div>
          <DemoEventsViewsSummaryMobile view={view} />
          <DemoSearchSummary
            width={width}
            searchCategory={searchCategory}
            searchDirection={searchDirection}
            searchFormat={searchFormat}
            view={view}
          />
          <DemoSettingsMobile
            view={view}
            toolbarActiveHighlight={toolbarActiveHighlight}
            setToolbarActiveHighlight={setToolbarActiveHighlight}
            searchDirection={searchDirection}
            setSearchDirection={setSearchDirection}
            searchFormat={searchFormat}
            setSearchFormat={setSearchFormat}
            searchCategory={searchCategory}
            setSearchCategory={setSearchCategory}
            demoBigTags={demoBigTags}
            demoTinyTags={demoTinyTags}
            demoTinyTagGroups={demoTinyTagGroups}
            demoCommunityFollowing={demoCommunityFollowing}
            demoCommunityFollowingGroups={demoCommunityFollowingGroups}
            selectedMyEvents={selectedMyEvents}
            selectedBigTags={selectedBigTags}
            selectedTinyTags={selectedTinyTags}
            selectedTinyTagGroupIds={selectedTinyTagGroupIds}
            selectedFollowingIds={selectedFollowingIds}
            selectedFollowingGroupIds={selectedFollowingGroupIds}
            selectAllBTBtn={selectAllBTBtn}
            removeSelectedBTs={removeSelectedBTs}
            addSelectedBTs={addSelectedBTs}
            removeAllSelectedBTs={removeAllSelectedBTs}
            setSelectAllBTBtn={setSelectAllBTBtn}
            selectAllMyEventsBtn={selectAllMyEventsBtn}
            setSelectedMyEvents={setSelectedMyEvents}
            setSelectAllMyEventsBtn={setSelectAllMyEventsBtn}
            selectAllTTBtn={selectAllTTBtn}
            removeSelectedTinyTags={removeSelectedTinyTags}
            addSelectedTinyTags={addSelectedTinyTags}
            removeSelectedTinyTagGroupIds={removeSelectedTinyTagGroupIds}
            addSelectedTinyTagGroupIds={addSelectedTinyTagGroupIds}
            removeAllSelectedTinyTagGroupIds={removeAllSelectedTinyTagGroupIds}
            setSelectAllTTBtn={setSelectAllTTBtn}
            removeAllSelectedTinyTags={removeAllSelectedTinyTags}
            selectAllAccountsFollowingBtn={selectAllAccountsFollowingBtn}
            setSelectAllAccountsFollowingBtn={setSelectAllAccountsFollowingBtn}
            removeAllSelectedAccountsFollowingIds={
              removeAllSelectedAccountsFollowingIds
            }
            removeSelectedAccountsFollowingId={
              removeSelectedAccountsFollowingId
            }
            addSelectedAccountsFollowingId={addSelectedAccountsFollowingId}
            removeSelectedAccountsFollowingGroupIds={
              removeSelectedAccountsFollowingGroupIds
            }
            addSelectedAccountsFollowingGroupIds={
              addSelectedAccountsFollowingGroupIds
            }
            removeAllSelectedAccountsFollowingGroupIds={
              removeAllSelectedAccountsFollowingGroupIds
            }
          />
        </div>
      )}
    </>
  );
};

export default DemoEventSettings;
