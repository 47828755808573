import { useEffect, useRef, useState } from 'react';

//components
import ExploreFeaturesNavBtns from './ExploreFeatures/ExploreFeaturesNavBtns';
import ExploreFeaturesButtons from './ExploreFeatures/ExploreFeaturesButtons';
import ExploreFeaturesFindEvents from './ExploreFeatures/FindEvents/ExploreFeaturesFindEvents';
import ExploreFeaturesSpeaker from './ExploreFeatures/Speaker/ExploreFeaturesSpeaker';
import ExploreFeaturesOrganize from './ExploreFeatures/Organize/ExploreFeaturesOrganize';

//utils
import ScrollTo from '../../utils/UI/ScrollTo';

const ExploreFeatures = () => {
  //state
  const [exploreFeature, setExploreFeature] = useState('findEvents');

  //UI
  const [currentIndex, setCurrentIndex] = useState(0);

  //ref
  const scrollTimer = useRef();

  useEffect(() => {
    return () => {
      if (scrollTimer.current) {
        clearTimeout(scrollTimer.current);
      }
    };
  }, []);

  function changeExploreTopic(newExploreFeature) {
    if (newExploreFeature === 'findEvents') {
      if (exploreFeature !== 'findEvents') {
        setCurrentIndex(0);
      }
      setExploreFeature('findEvents');
    }

    if (newExploreFeature === 'organize') {
      if (exploreFeature !== 'organize') {
        setCurrentIndex(0);
      }
      setExploreFeature('organize');
    }

    if (newExploreFeature === 'speaker') {
      if (exploreFeature !== 'speaker') {
        setCurrentIndex(0);
      }
      setExploreFeature('speaker');
    }

    scrollTimer.current = setTimeout(() => {
      ScrollTo({
        focusId: 'exploreFeatures',
        behavior: 'smooth',
        scrollToElementId: 'exploreFeatures',
      });
    }, 300);
  }

  return (
    <div className="explore-features bg-cream">
      <div className="explore-base-text flex-center">
        <h2 className="text-center mrg-b48" id="exploreFeatures">
          Let's explore some features!
        </h2>
      </div>
      <ExploreFeaturesButtons
        exploreFeature={exploreFeature}
        changeExploreTopic={changeExploreTopic}
      />
      <ExploreFeaturesNavBtns
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        exploreFeature={exploreFeature}
      />
      {exploreFeature === 'findEvents' && (
        <ExploreFeaturesFindEvents currentIndex={currentIndex} />
      )}
      {exploreFeature === 'organize' && (
        <ExploreFeaturesOrganize currentIndex={currentIndex} />
      )}
      {exploreFeature === 'speaker' && (
        <ExploreFeaturesSpeaker currentIndex={currentIndex} />
      )}
    </div>
  );
};

export default ExploreFeatures;
