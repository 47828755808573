const ExploreMyndFullIntroduction = ({ ExploreMyndFull }) => {
  return (
    <div className="explore-myndfull-introduction explore-base-text bg-cream">
      <h2>{ExploreMyndFull.whatIsMyndFull}</h2>
      <p className="mrg-t48 mrg-t24--ph ">
        MyndFull is your central platform for discovering, hosting, and
        participating in academic and educational events. We bring together the
        best tools and resources to connect educators and learners &ndash; all
        in one place.
      </p>
      <p className="mrg-t24">
        Right now, these events are scattered across educational institution
        websites, non-education focused event platforms, email listservs, and
        social media posts.
      </p>
      <p className="fwb italic mrg-t36">
        This should've changed a long time ago &mdash; now it has.
      </p>
    </div>
  );
};

export default ExploreMyndFullIntroduction;
