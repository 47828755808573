import { organize, search_dark, speaker_dark } from '../../../assets/icons';

const ExploreFeaturesButtons = ({ exploreFeature, changeExploreTopic }) => {
  return (
    <ul className="explore-features-btns">
      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'findEvents'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => {
            changeExploreTopic('findEvents');
          }}
        >
          <img src={search_dark} alt="" />
          <p>Finding Events</p>
        </button>
      </li>

      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'organize'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => changeExploreTopic('organize')}
        >
          <img src={organize} alt="" />
          <p>Organizing</p>
        </button>
      </li>

      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'speaker'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => changeExploreTopic('speaker')}
        >
          <img src={speaker_dark} alt="" />
          <p>Speaking</p>
        </button>
      </li>
    </ul>
  );
};

export default ExploreFeaturesButtons;
